<template>
    <div>
        <Row class="mutation" type="flex" justify="center">
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/mutation/m-1.png" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <div>The shape of human body is the foundation of fashion because all the clothes we designed will be eventually worn on different human bodies for various purposes, such as showing identities, uniqueness, and functionality. Mutation, however, can be devastating and inspiring. My inspiration comes from the genetic mutation caused by the nuclear disaster e.g. Chernobyl disaster that will lead to a series of abnormal development of human body and further generate a mutated body shape. Apart from the external factor i.e. nuclear disasters, mutation also occurs in medical fields.</div>
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/mutation/m-2.png" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/mutation/m-3.jpg" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/mutation/m-4.jpg" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/mutation/m-5.png" alt="">
            </Col>
            <Col :span="22" :md="16" :lg="16" class="smallImage">
                <img src="@img/mutation/m-6.jpg" alt="">
            </Col>
            <Col :span="22" :md="16" :lg="16" class="smallImage">
                <img src="@img/mutation/m-7.jpg" alt="">
            </Col>
        </Row>
        <copy-right />
    </div>
</template>

<script>
import common from '@M/common';
import CopyRight from '@C/CopyRight';

export default {
  name: 'Mutation',
  mixins: [common],
  components: {
      CopyRight
  }
}
</script>

<style lang="scss">

.mutation {
    flex-direction: column;
    align-items: center;
    > div {
        max-width: 800px;
        margin-bottom: 2rem;
        color: #000;
        font-size: 13px;
        line-height: 2em;

        &:first-child {
            margin-top: 5rem;
        }
        img {
            width: 100%;
            height: inherit;
        }
    }
    .smallImage {
        max-width: 650px;
    }
}
</style>
